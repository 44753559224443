<template>
	<section class="layer" style="display:block">
		<div class="layer__dim"></div>
		<div class="layer__wrap">
			<div class="layer__inner">
				<header class="layer__header">
					<h3 class="layer__title">
						공유하기
					</h3>
					<button type="button" class="btn-close">
						<i class="icon icon-close--light"></i>
					</button>
				</header>
				<div class="layer__body">
					<ul class="sns sns--share">
						<li>
							<a href="#">
								<i class="icon icon-sns icon-kt"></i>
								<span class="sns__title">
									카카오톡 공유하기
								</span>
							</a>
							<i class="icon icon-right icon-right--light"></i>
						</li>
						<li>
							<a href="#">
								<i class="icon icon-sns icon-sms"></i>
								<span class="sns__title">
									SMS 공유하기
								</span>
								
							</a>
							<i class="icon icon-right icon-right--light"></i>
						</li>
						<li>
							<a href="#">
								<i class="icon icon-sns icon-link"></i>
								<span class="sns__title">
									링크복사
								</span>
							</a>
							<i class="icon icon-right icon-right--light"></i>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</section>
</template>